import React from "react";
import "./index.scss";

const Video = ({ getDisplayError }) => (
  <div className="PgCMain-I-Screenshot-video">
    <div
      className="PgCMain-I-Screenshot-video__errorMessage"
      style={{ display: getDisplayError ? "flex" : "none" }}
    >
      抱歉, 您的浏览器无法播放视频。
    </div>
    <iframe
      id="Cn-Video"
      src="https://player.bilibili.com/player.html?aid=293112060&bvid=BV15f4y1E7kB&cid=409417511&page=1&autoplay=1"
      scrolling="no"
      border={0}
      frameBorder="no"
      allowFullScreen={false}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  </div>
);

export default Video;
