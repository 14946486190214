import PngATRowTwoOne from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo.png"
import PngATRowTwoOne2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo@2x.png"
import PngATRowTwoTwo from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_1200.png"
import PngATRowTwoTwo2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_1200@2x.png"
import PngATRowTwoThree from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_992.png"
import PngATRowTwoThree2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_992@2x.png"
import PngATRowTwoFour from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_768.png"
import PngATRowTwoFour2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_768@2x.png"
import PngATRowTwoFive from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_320.png"
import PngATRowTwoFive2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowTwo_320@2x.png"

import PngATRowThreeOne from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree.png"
import PngATRowThreeOne2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree@2x.png"
import PngATRowThreeTwo from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_1200.png"
import PngATRowThreeTwo2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_1200@2x.png"
import PngATRowThreeThree from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_992.png"
import PngATRowThreeThree2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_992@2x.png"
import PngATRowThreeFour from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_768.png"
import PngATRowThreeFour2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_768@2x.png"
import PngATRowThreeFive from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_320.png"
import PngATRowThreeFive2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowThree_320@2x.png"

import PngATRowFourOne from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour.png"
import PngATRowFourOne2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour@2x.png"
import PngATRowFourTwo from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_1200.png"
import PngATRowFourTwo2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_1200@2x.png"
import PngATRowFourThree from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_992.png"
import PngATRowFourThree2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_992@2x.png"
import PngATRowFourFour from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_768.png"
import PngATRowFourFour2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_768@2x.png"
import PngATRowFourFive from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_320.png"
import PngATRowFourFive2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFour_320@2x.png"

import PngATRowFiveOne from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive.png"
import PngATRowFiveOne2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive@2x.png"
import PngATRowFiveTwo from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_1200.png"
import PngATRowFiveTwo2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_1200@2x.png"
import PngATRowFiveThree from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_992.png"
import PngATRowFiveThree2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_992@2x.png"
import PngATRowFiveFour from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_768.png"
import PngATRowFiveFour2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_768@2x.png"
import PngATRowFiveFive from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_320.png"
import PngATRowFiveFive2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-at_rowFive_320@2x.png"

import PngATRowSixOne from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix.png"
import PngATRowSixOne2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix@2x.png"
import PngATRowSixTwo from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_1200.png"
import PngATRowSixTwo2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_1200@2x.png"
import PngATRowSixThree from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_992.png"
import PngATRowSixThree2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_992@2x.png"
import PngATRowSixFour from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_768.png"
import PngATRowSixFour2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_768@2x.png"
import PngATRowSixFive from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_320.png"
import PngATRowSixFive2x from "../../../../assets/images/raster/pages/cn/main/Tools/cn-Tools_rowSix_320@2x.png"

const PG_C_MAIN_TOOLS_CONTENT = {
  rowOne: {
    mainTitle: "获取做好亚马逊的必备工具",
  },
  rowTwo: {
    title: "找到高利润产品在亚马逊上销售",
    text: [
      "使用包含5.5亿产品的数据库找到最有潜力的产品",
      "使用传奇的PRO插件来寻找具有最高利润潜力的利基和产品并评估竞争程度",
      "在最准确并值得信赖的数据分析的基础上做判断",
      "您甚至会收到前1%的高利润产品报告",
    ],
    img: [
      PngATRowTwoOne,
      PngATRowTwoOne2x,
      PngATRowTwoTwo,
      PngATRowTwoTwo2x,
      PngATRowTwoThree,
      PngATRowTwoThree2x,
      PngATRowTwoFour,
      PngATRowTwoFour2x,
      PngATRowTwoFive,
      PngATRowTwoFive2x,
    ],
  },
  rowThree: {
    title: "学习如何在亚马逊上销售",
    text: "通过观看分步视频教程，以及一系列由营收7位数的亚马逊卖家举办的大师班，详细了解如何挑选、采购与销售高利润产品。",
    img: [
      PngATRowThreeOne,
      PngATRowThreeOne2x,
      PngATRowThreeTwo,
      PngATRowThreeTwo2x,
      PngATRowThreeThree,
      PngATRowThreeThree2x,
      PngATRowThreeFour,
      PngATRowThreeFour2x,
      PngATRowThreeFive,
      PngATRowThreeFive2x,
    ],
  },
  rowFour: {
    title: "在阿里巴巴上寻找本地与国际供应商",
    text: [
      "在任何国家(包括美国和中国)找到值得信赖的供应商",
      "一键寻找阿里巴巴供应商。多亏阿里巴巴和AMZ侦探的合作，我们的用户可以享受最佳采购选择。",
      "在阿里巴巴上找到您竞争对手最成功的产品来扩展您的产品范围",
      "通过对供应商的评级和销售量进行比较，选择最可靠的供应商以降低您的业务风险",
    ],
    img: [
      PngATRowFourOne,
      PngATRowFourOne2x,
      PngATRowFourTwo,
      PngATRowFourTwo2x,
      PngATRowFourThree,
      PngATRowFourThree2x,
      PngATRowFourFour,
      PngATRowFourFour2x,
      PngATRowFourFive,
      PngATRowFourFive2x,
    ],
  },
  rowFive: {
    title: "打造亚马逊爆款",
    text: [
      "获得为创造一个有吸引力的产品页面所需要的关键词，并使用AMZ侦探关键词工具来运行PPC广告",
      "获取关于如何改善产品页面并击败竞争对手的建议",
      "使用完美的产品页面和PPC广告来占据亚马逊搜索结果的顶部，并让您销量增长达400%",
      "使用内置阿里巴巴按钮来快速找到任何产品的供应商",
    ],
    img: [
      PngATRowFiveOne,
      PngATRowFiveOne2x,
      PngATRowFiveTwo,
      PngATRowFiveTwo2x,
      PngATRowFiveThree,
      PngATRowFiveThree2x,
      PngATRowFiveFour,
      PngATRowFiveFour2x,
      PngATRowFiveFive,
      PngATRowFiveFive2x,
    ],
  },
  rowSix: {
    title: "监视您竞争对手",
    text: "监视竞争对手的销量、价格、排名以及库存来了解其战略并让您保持领先优势",
    img: [
      PngATRowSixOne,
      PngATRowSixOne2x,
      PngATRowSixTwo,
      PngATRowSixTwo2x,
      PngATRowSixThree,
      PngATRowSixThree2x,
      PngATRowSixFour,
      PngATRowSixFour2x,
      PngATRowSixFive,
      PngATRowSixFive2x,
    ],
  },
}

export default PG_C_MAIN_TOOLS_CONTENT
