import React from "react"

import usaIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/usa_icon.svg"
import germanyIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/germany_icon.svg"
import indiaIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/india_icon.svg"
import italyIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/italy_icon.svg"
import ukIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/uk_icon.svg"
import canadaIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/canada_icon.svg"
import franceIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/france_icon.svg"
import mexicoIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/mexico_icon.svg"
import spainIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/spain_icon.svg"
import japanIconSvg from "../../../../assets/images/vector/pages/cn/main/SectionThree/japan_icon.svg"

const PG_C_MAIN_COUNTRIES_CONTENT = {
  img: [
    usaIconSvg,
    germanyIconSvg,
    indiaIconSvg,
    italyIconSvg,
    ukIconSvg,
    canadaIconSvg,
    franceIconSvg,
    mexicoIconSvg,
    spainIconSvg,
    japanIconSvg,
  ],
}
export default PG_C_MAIN_COUNTRIES_CONTENT
