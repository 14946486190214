import React, { useState } from "react";
import TABS from "./index.content";
import Paragraph from "./Paragraph";
import Screen from "./Screen";
import "./index.scss";

const Setting = () => {
  const [indexOpenTab, setIndexOpenTab] = useState(0);
  return (
    <section className="PgCMain-S">
      <div className="PgCMain-S__container">
        <div className="PgCMain-S__wrapperTitle">
          <h2 className="PgCMain-S__title">AMZ侦探 PRO Chrome 和 Edge 插件</h2>
          <p>在Chrome和Edge浏览器上一键获取竞品信息</p>
        </div>
        <div className="PgCMain-S__secOne">
          <div className="PgCMain-S__wrapList">
            <ul id="check">
              {TABS.map(({ title, cnNumber, text }, index) => {
                const parameter = index !== indexOpenTab ? "closed" : "open";
                return (
                  <li key={title} onClick={() => setIndexOpenTab(index)}>
                    <div className={`PgCMain-S__${parameter}`}>
                      <div className={`PgCMain-S__${parameter}-text`}>
                        <span>{`${cnNumber}.`}</span>
                        {title}
                      </div>
                      {parameter === "open" && <Paragraph text={text} />}
                      <div className={`PgCMain-S__${parameter}-line`} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="PgCMain-S__wrapImg">
            <Screen screen={TABS[indexOpenTab || 0].screen} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Setting;
