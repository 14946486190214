import React from "react";

const CountriesIcons = ({ start, end, img }) => {
  const listIconCountries = [];
  for (let i = start; i < end; i++) {
    listIconCountries.push(
      <li key={i + "Countries"}>
        <img src={img[i]} alt="" />
      </li>
    );
  }
  return listIconCountries;
};

export default CountriesIcons;
