import React from "react";

const ToolsRowImage = ({ data, number, widthImg, heightImg, classes }) => (
  <picture className={`${classes}${number}-Img`}>
    <source
      media="(max-width: 767px)"
      srcSet={`${data[8]} 1x, ${data[9]} 2x`}
    />
    <source
      media="(min-width: 768px) and (max-width: 991px)"
      srcSet={`${data[6]} 1x, ${data[7]} 2x`}
    />
    <source
      media="(min-width: 992px) and (max-width: 1199px)"
      srcSet={`${data[4]} 1x, ${data[5]} 2x`}
    />
    <source
      media="(min-width: 1200px) and (max-width: 1365px)"
      srcSet={`${data[2]} 1x, ${data[3]} 2x`}
    />
    <source
      media="(min-width: 1366px)"
      srcSet={`${data[0]} 1x, ${data[1]} 2x`}
    />
    <img
      src={`${data[0]}`}
      alt="img"
      className={`${classes}${number}-Img`}
      width={`${widthImg}`}
      height={`${heightImg}`}
      loading="lazy"
    />
  </picture>
);
export default ToolsRowImage;
