import React from "react";

const Screen = ({ screen }) => {
  const {
    JPG,
    JPG_2x,
    JPG_1200,
    JPG_1200_2x,
    JPG_992,
    JPG_992_2x,
    JPG_768,
    JPG_768_2x,
    JPG_MOB,
    JPG_MOB_2x,
  } = screen;
  return (
    <picture>
      <source
        media="(max-width: 767px)"
        srcSet={`${JPG_MOB} 1x, ${JPG_MOB_2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${JPG_768} 1x, ${JPG_768_2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${JPG_992} 1x, ${JPG_992_2x} 2x`}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${JPG_1200} 1x, ${JPG_1200_2x} 2x`}
      />
      <source
        media="(min-width: 1366px)"
        srcSet={`${JPG} 1x, ${JPG_2x} 2x`}
      />
      <img src={`${JPG}`} alt="img" />
    </picture>
  );
};

export default Screen;
