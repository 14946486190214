import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import BRANDS_CONTENT from "./index.content";
import Item from "./Item";
import "./index.scss";

const Brands = () => {
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);

  return isNotInitMobile ? (
    <div className="PgCMain-B">
      <Item data={BRANDS_CONTENT} start={0} end={4} section="secOne" />
      <Item
        data={BRANDS_CONTENT}
        start={4}
        end={BRANDS_CONTENT.length}
        section="secTwo"
      />
    </div>
  ) : (
    <div className="PgCMain-B">
      <Item
        data={BRANDS_CONTENT}
        start={0}
        end={BRANDS_CONTENT.length}
        section="secOne"
      />
    </div>
  );
};

export default Brands;
