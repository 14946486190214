import React from "react";
import PG_C_MAIN_SECTION_RATING_REVIEW_CONTENT from "./index.content";
import RatingReview from "../../../common/RatingReview/NewRatingReview";
import "./index.scss";

const SectionRatingReview = () => (
  <section className="PgCMain-SectionRatingReview">
    <RatingReview
      customClass="PgCMain-reviews"
      isMainPage
      excellentText="非常好"
      trustText="基于Trustpilot上的评论"
      posts={PG_C_MAIN_SECTION_RATING_REVIEW_CONTENT}
    />
  </section>
);

export default SectionRatingReview;
