import React from "react";
import PG_C_MAIN_TOOLS_CONTENT from "./index.content";
import ToolsRow from "./ToolsRow";
// import ToolsImageOne from "./ToolsImageOne";
import "./index.scss";

const Tools = () => {
  const { rowOne, rowTwo, rowThree, rowFour, rowFive, rowSix } =
    PG_C_MAIN_TOOLS_CONTENT;

  return (
    <section className="PgCMain-Tools">
      <div className="PgCMain-Tools__container">
        <div className="PgCMain-Tools__wrapRowOne">
          <div className="PgCMain-Tools__rowOne">
            <div className="PgCMain-Tools__rowOne-title">
              {rowOne.mainTitle}
            </div>
          </div>
        </div>
        {/* <ToolsRow*/}
        {/*  content={rowTwo}*/}
        {/*  number={'Two'}*/}
        {/*  widthImg={280}*/}
        {/*  heightImg={138}*/}
        {/*  parentClass={"PgCMain-Tools"}*/}
        {/*  image={<ToolsImageOne />} />*/}
        <ToolsRow
          content={rowTwo}
          number="Two"
          widthImg={280}
          heightImg={138}
          parentClass="PgCMain-Tools"
        />
        <ToolsRow
          content={rowThree}
          number="Three"
          widthImg={280}
          heightImg={150}
          parentClass="PgCMain-Tools"
          typeText="str"
        />
        <ToolsRow
          content={rowFour}
          number="Four"
          widthImg={280}
          heightImg={188}
          parentClass="PgCMain-Tools"
        />
        <ToolsRow
          content={rowFive}
          number="Five"
          widthImg={280}
          heightImg={161}
          parentClass="PgCMain-Tools"
        />
        <ToolsRow
          content={rowSix}
          number="Six"
          widthImg={280}
          heightImg={142}
          parentClass="PgCMain-Tools"
          typeText="str"
        />
      </div>
    </section>
  );
};
export default Tools;
