import React from "react";
import SVG_Star from "../../../../assets/images/vector/pages/cn/main/Rating/Star.svg";
import SVG_Rectangle from "../../../../assets/images/vector/pages/cn/main/Rating/Rectangle.svg";
import "./index.scss";

const RatingStars = ({ classes }) => {
  const rating = [];
  for (let i = 0; i < 5; i++) {
    rating.push(
      <img key={"star_" + i} src={i === 4 ? SVG_Rectangle : SVG_Star} alt="*" />
    );
  }
  return <div className={classes}>{rating}</div>;
};
export default RatingStars;
