import SvgDisney from "../../../../assets/images/vector/pages/cn/main/Brands/brands-disney.svg"
import SvgBitdefender from "../../../../assets/images/vector/pages/cn/main/Brands/brands-bitdefender.svg"
import SvgRedbull from "../../../../assets/images/vector/pages/cn/main/Brands/brands-redbull.svg"
import SvgSteelseries from "../../../../assets/images/vector/pages/cn/main/Brands/brands-steelseries.svg"
import SvgDell from "../../../../assets/images/vector/pages/cn/main/Brands/brands-dell.svg"
import SvgHuawei from "../../../../assets/images/vector/pages/cn/main/Brands/brands-huawei.svg"
import SvgCasio from "../../../../assets/images/vector/pages/cn/main/Brands/brands-casio.svg"
import SvgAlibaba from "../../../../assets/images/vector/pages/cn/main/Brands/brands-alibaba.svg"

const BRANDS_CONTENT = [
    {
      nameBrand: "disney",
      img: SvgDisney,
    },
    {
      nameBrand: "bitdefender",
      img: SvgBitdefender,
    },
    {
      nameBrand: "redbull",
      img: SvgRedbull,
    },
    {
      nameBrand: "steelseries",
      img: SvgSteelseries,
    },
    {
      nameBrand: "dell",
      img: SvgDell,
    },
    {
      nameBrand: "huawei",
      img: SvgHuawei,
    },
    {
      nameBrand: "casio",
      img: SvgCasio,
    },
    {
      nameBrand: "alibaba",
      img: SvgAlibaba,
    },
  ]
export default BRANDS_CONTENT
