import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import { PAGES } from "../../../../helpers/constants";
import { getTrueClasses } from "../../../../helpers";
import GetTitle from "./GetTitle";
import GetForm from "./GetForm";
import "../ControlsEmailCollect/index.scss";
import CnRedirectedButton from "./CnRedirectedButton";
import CnSectionFour from "../../../cn/main/SectionFour";
import "./index.scss";

const EmailCollectCn = (props) => {
  const {
    title,
    page,
    buttonTextFirstState,
    buttonTextSecondState,
    installViewCustomClass,
    customClass,
    placeholderInput,
    information,
    isNodeTitle,
    noCreditCardRequired,
    btnMaxContent,
    redirectUrl,
    onEmailChange,
    emailValue,
    onSubmit,
    onError,
    onKey,
    handleClick,
    isCnUser,
    showMobileProPopup,
    formOpacity,
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const classes = getTrueClasses(
    "emailView",
    "emailCollect",
    `emailCollect-${PAGES[page].toLowerCase()}`,
    page === PAGES.PD ? "emailCollect-pd" : "emailCollect_center",
    customClass
  );
  return !isAuth ? (
    <div className={classes} style={{ opacity: formOpacity }}>
      <GetTitle
        isNodeTitle={isNodeTitle}
        title={title}
        noCreditCardRequired={noCreditCardRequired}
      />
      <GetForm
        placeholderInput={placeholderInput}
        buttonTextFirstState={buttonTextFirstState}
        information={information}
        btnMaxContent={btnMaxContent}
        emailValue={emailValue}
        onEmailChange={onEmailChange}
        onSubmit={onSubmit}
        onError={onError}
        onKey={onKey}
      />
    </div>
  ) : isCnUser ? (
    <CnSectionFour handleClick={handleClick} formOpacity={formOpacity} />
  ) : (
    <CnRedirectedButton
      redirectUrl={redirectUrl}
      title={buttonTextSecondState}
      page={page}
      customClass={installViewCustomClass}
      showMobileProPopup={showMobileProPopup}
      formOpacity={formOpacity}
    />
  );
};

EmailCollectCn.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  buttonTextSecondState: PropTypes.string,
  installViewCustomClass: PropTypes.string,
  customClass: PropTypes.string,
  placeholderInput: PropTypes.string,
  information: PropTypes.string,
  isNodeTitle: PropTypes.bool,
  noCreditCardRequired: PropTypes.string,
  redirectUrl: PropTypes.string,
  onEmailChange: PropTypes.func,
  emailValue: PropTypes.string,
  onSubmit: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
};

export default EmailCollectCn;
