import React from "react";

const INTRO_CONTENT = {
  unauthorized: {
    title: "免费注册获取：",
    list: [
      "多合一工具组合试用版",
      "热门产品与亚马逊趋势报告",
      `“如何在亚马逊上销售” 分步教程`,
    ],
  },
  trial: {
    title: "立即免费获得:",
    list: [
      "多合一工具组合试用版",
      "热门产品与亚马逊趋势报告",
      `“如何在亚马逊上销售” 分步教程`,
    ],
    style: [
      "PgCMain-I-Offer-trial",
      "PgCMain-I-Offer-title__trial",
      "PgCMain-I-Offer-list__trial",
    ],
  },
  paid: {
    title: "立即免费获得:",
    list: [
      "多合一工具组合试用版",
      "热门产品与亚马逊趋势报告",
      `“如何在亚马逊上销售” 分步教程`,
    ],
    style: [
      "PgCMain-I-Offer-paid",
      "PgCMain-I-Offer-title__paid",
      "PgCMain-I-Offer-list__paid",
    ],
  },
}
export default INTRO_CONTENT
