import React from "react";
import RatingStars from "./RatingStars";
import "./index.scss";

const Rating = () => (
  <section className="PgCMain-R">
    <div className="PgCMain-R__container">
      <div className="PgCMain-R__row">
        <div className="PgCMain-R__wrap">
          <RatingStars classes="PgCMain-R__Stars" />
          <p className="PgCMain-R__note">
            Chrome网上应用商店评分<span>|</span>50万用户
          </p>
        </div>
        <h2>已有1,000,000种高利润产品</h2>
      </div>
    </div>
  </section>
);

export default Rating;
