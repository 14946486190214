import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import Video from "./video";
import FastImage from "../../../../../containers/common/FastImage";
import "./index.scss";

const IntroScreenshot = (props) => {
  const { data, isShowVideo, setIsShowVideo, getDisplayError } = props;
  return (
    <div
      className={getTrueClasses(
        "PgCMain-I-Screenshot",
        !isShowVideo && "PgCMain-I-Screenshot-play"
      )}
      onClick={() => setIsShowVideo(true)}
    >
      {isShowVideo ? (
        <Video getDisplayError={getDisplayError} />
      ) : (
        <FastImage
          imageData={data}
          wrapImageClassName="PgCMain-I-Screenshot__fastImage"
          imageAltName="home image"
          isAdaptive
          isWithRatio
          defaultName="cn-home"
        />
      )}
    </div>
  );
};

IntroScreenshot.propTypes = {
  isShowVideo: PropTypes.bool,
  setIsShowVideo: PropTypes.func,
};

export default IntroScreenshot;
