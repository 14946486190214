import React from "react";

const ToolsRowList = ({ text }) => {
  const res = [];
  for (let i = 0; i < text.length; i++) {
    res.push(
      <li key={i + "GetListAt"}>
        <span>{i + 1}</span>
        {text[i]}
      </li>
    );
  }
  return <ul>{res}</ul>;
};

export default ToolsRowList;
