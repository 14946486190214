import React, { useContext } from "react";
import { PAGES, POPUP_TYPES } from "../../../../helpers/constants";
import track from "../../../../tracking/analytics";
import { getTrueClasses } from "../../../../helpers";
import "../ControlsEmailCollect/index.scss";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import { LayoutContext } from "../../../../store/context/layout-context";
import { targetStatus } from "../../../../helpers/browser";

const CnRedirectedButton = (props) => {
  const {
    title,
    redirectUrl,
    background = "gradient",
    page,
    customClass,
    showMobileProPopup,
    formOpacity,
  } = props;
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);
  const { popupState, openPopup } = useContext(LayoutContext);

  const clickHandler = (e) => {
    e.preventDefault();
    if (showMobileProPopup && isNotInitMobile && !popupState.open) {
      openPopup(POPUP_TYPES.MOBILE_PRO);
    } else {
      track("CNIndex", redirectUrl, "TryForFree", undefined, "LANDING");
      window.open(redirectUrl, targetStatus() ? "_blank" : "_self");
    }
  };
  return (
    <div
      className={getTrueClasses(
        "installView",
        "emailCollect",
        page && `installView-${PAGES[page].toLowerCase()}`,
        customClass
      )}
      style={{ opacity: formOpacity }}
    >
      <a
        className={`bt-orange bg-${background}`}
        onClick={clickHandler}
        rel="nofollow"
        href={redirectUrl}
        target="_blank"
      >
        {title}
      </a>
    </div>
  );
};

export default CnRedirectedButton;
