import React from "react";

const Item = (props) => {
  const { data, start, end, section } = props;
  const listBrands = [];
  for (let i = start; i < end; i++) {
    listBrands.push(
      <img
        key={i}
        className={`PgCMain-B__${data[i].nameBrand}`}
        src={`${data[i].img}`}
        alt=""
      />
    );
  }
  return <div className={`PgCMain-B__${section}`}>{listBrands}</div>;
};
export default Item;
