import JpgSecOneOneOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_01.jpg";
import JpgSecOneOneOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_01@15x.jpg";
import JpgSecOneOneTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_02.jpg";
import JpgSecOneOneTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_02@15x.jpg";
import JpgSecOneOneThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_03.jpg";
import JpgSecOneOneThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_03@15x.jpg";
import JpgSecOneOneFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_04.jpg";
import JpgSecOneOneFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_04@15x.jpg";
import JpgSecOneOneFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_05.jpg";
import JpgSecOneOneFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-01_05@15x.jpg";

import JpgSecOneTwoOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_01.jpg";
import JpgSecOneTwoOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_01@15x.jpg";
import JpgSecOneTwoTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_02.jpg";
import JpgSecOneTwoTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_02@15x.jpg";
import JpgSecOneTwoThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_03.jpg";
import JpgSecOneTwoThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_03@15x.jpg";
import JpgSecOneTwoFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_04.jpg";
import JpgSecOneTwoFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_04@15x.jpg";
import JpgSecOneTwoFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_05.jpg";
import JpgSecOneTwoFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-02_05@15x.jpg";

import JpgSecOneThreeOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_01.jpg";
import JpgSecOneThreeOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_01@15x.jpg";
import JpgSecOneThreeTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_02.jpg";
import JpgSecOneThreeTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_02@15x.jpg";
import JpgSecOneThreeThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_03.jpg";
import JpgSecOneThreeThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_03@15x.jpg";
import JpgSecOneThreeFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_04.jpg";
import JpgSecOneThreeFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_04@15x.jpg";
import JpgSecOneThreeFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_05.jpg";
import JpgSecOneThreeFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-03_05@15x.jpg";

import JpgSecOneFourOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_01.jpg";
import JpgSecOneFourOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_01@15x.jpg";
import JpgSecOneFourTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_02.jpg";
import JpgSecOneFourTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_02@15x.jpg";
import JpgSecOneFourThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_03.jpg";
import JpgSecOneFourThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_03@15x.jpg";
import JpgSecOneFourFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_04.jpg";
import JpgSecOneFourFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_04@15x.jpg";
import JpgSecOneFourFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_05.jpg";
import JpgSecOneFourFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-04_05@15x.jpg";

import JpgSecOneFiveOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_01.jpg";
import JpgSecOneFiveOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_01@15x.jpg";
import JpgSecOneFiveTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_02.jpg";
import JpgSecOneFiveTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_02@15x.jpg";
import JpgSecOneFiveThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_03.jpg";
import JpgSecOneFiveThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_03@15x.jpg";
import JpgSecOneFiveFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_04.jpg";
import JpgSecOneFiveFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_04@15x.jpg";
import JpgSecOneFiveFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_05.jpg";
import JpgSecOneFiveFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-05_05@15x.jpg";

import JpgSecOneSixOne from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_01.jpg";
import JpgSecOneSixOne2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_01@15x.jpg";
import JpgSecOneSixTwo from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_02.jpg";
import JpgSecOneSixTwo2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_02@15x.jpg";
import JpgSecOneSixThree from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_03.jpg";
import JpgSecOneSixThree2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_03@15x.jpg";
import JpgSecOneSixFour from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_04.jpg";
import JpgSecOneSixFour2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_04@15x.jpg";
import JpgSecOneSixFive from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_05.jpg";
import JpgSecOneSixFive2x from "../../../../assets/images/raster/pages/cn/main/SectionOne/cn-s1-06_05@15x.jpg";

const ONE = {
  title: `寻找高利润的热销产品`,
  cnNumber : "一",
  text: [
    "利用亚马逊产品数据库寻找高需求低竞争的产品",
    "找到一款月销爆款的高利润产品",
  ],
  screen: {
    JPG: JpgSecOneOneOne,
    JPG_2x: JpgSecOneOneOne2x,
    JPG_1200: JpgSecOneOneTwo,
    JPG_1200_2x: JpgSecOneOneTwo2x,
    JPG_992: JpgSecOneOneThree,
    JPG_992_2x: JpgSecOneOneThree2x,
    JPG_768: JpgSecOneOneFour,
    JPG_768_2x: JpgSecOneOneFour2x,
    JPG_MOB: JpgSecOneOneFive,
    JPG_MOB_2x: JpgSecOneOneFive2x,
  },
};
const TWO = {
  title: `确定增长趋势`,
  cnNumber: "二",
  text: [
    "使用简单的图标来识别各种细分产品类别的过去与未来的增长趋势",
    "在竞争激烈之前抓住市场机会",
  ],
  screen: {
    JPG: JpgSecOneTwoOne,
    JPG_2x: JpgSecOneTwoOne2x,
    JPG_1200: JpgSecOneTwoTwo,
    JPG_1200_2x: JpgSecOneTwoTwo2x,
    JPG_992: JpgSecOneTwoThree,
    JPG_992_2x: JpgSecOneTwoThree2x,
    JPG_768: JpgSecOneTwoFour,
    JPG_768_2x: JpgSecOneTwoFour2x,
    JPG_MOB: JpgSecOneTwoFive,
    JPG_MOB_2x: JpgSecOneTwoFive2x,
  },
};
const THREE = {
  title: `验证细分市场创意`,
  cnNumber: "三",
  text: [
    "利用我们的总体市场评分来指导您的产品决策。简单解释一下某个类别的需求、竞争和盈利能力，看看某个特定的细分市场是否值得进入",
  ],
  screen: {
    JPG: JpgSecOneThreeOne,
    JPG_2x: JpgSecOneThreeOne2x,
    JPG_1200: JpgSecOneThreeTwo,
    JPG_1200_2x: JpgSecOneThreeTwo2x,
    JPG_992: JpgSecOneThreeThree,
    JPG_992_2x: JpgSecOneThreeThree2x,
    JPG_768: JpgSecOneThreeFour,
    JPG_768_2x: JpgSecOneThreeFour2x,
    JPG_MOB: JpgSecOneThreeFive,
    JPG_MOB_2x: JpgSecOneThreeFive2x,
  },
};
const FOUR = {
  title: `跟踪其他卖家`,
  cnNumber: "四",
  text: [
    "使用产品历史图形来跟踪任何产品，包括您的竞争对手列表",
    "从销售、价格、排名数据库了解竞争对手的策略是否有效",
  ],
  screen: {
    JPG: JpgSecOneFourOne,
    JPG_2x: JpgSecOneFourOne2x,
    JPG_1200: JpgSecOneFourTwo,
    JPG_1200_2x: JpgSecOneFourTwo2x,
    JPG_992: JpgSecOneFourThree,
    JPG_992_2x: JpgSecOneFourThree2x,
    JPG_768: JpgSecOneFourFour,
    JPG_768_2x: JpgSecOneFourFour2x,
    JPG_MOB: JpgSecOneFourFive,
    JPG_MOB_2x: JpgSecOneFourFive2x,
  },
};
const FIVE = {
  title: `计算您的利润与费用`,
  cnNumber: "五",
  text:  ["检查预算的每个细节从而准确估算利润，避免产生错误的财务报告"],
  screen: {
    JPG: JpgSecOneFiveOne,
    JPG_2x: JpgSecOneFiveOne2x,
    JPG_1200: JpgSecOneFiveTwo,
    JPG_1200_2x: JpgSecOneFiveTwo2x,
    JPG_992: JpgSecOneFiveThree,
    JPG_992_2x: JpgSecOneFiveThree2x,
    JPG_768: JpgSecOneFiveFour,
    JPG_768_2x: JpgSecOneFiveFour2x,
    JPG_MOB: JpgSecOneFiveFive,
    JPG_MOB_2x: JpgSecOneFiveFive2x,
  },
};
const SIX = {
  title: `预估未来的细分市场竞争情况`,
  cnNumber: "六",
  text:  [
    "比竞争对手领先一步，调查目前寻找细分市场的卖家数量，制定策略以避免市场饱和",
  ],
  screen: {
    JPG: JpgSecOneSixOne,
    JPG_2x: JpgSecOneSixOne2x,
    JPG_1200: JpgSecOneSixTwo,
    JPG_1200_2x: JpgSecOneSixTwo2x,
    JPG_992: JpgSecOneSixThree,
    JPG_992_2x: JpgSecOneSixThree2x,
    JPG_768: JpgSecOneSixFour,
    JPG_768_2x: JpgSecOneSixFour2x,
    JPG_MOB: JpgSecOneSixFive,
    JPG_MOB_2x: JpgSecOneSixFive2x,
  },
};
const TABS = [ONE, TWO, THREE, FOUR, FIVE, SIX];

export default TABS;
