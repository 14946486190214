import React from "react";
import ToolsRowList from "./ToolsRowList";
import ToolsRowImage from "./ToolsRowImage";

const ToolsRow = ({
  content,
  number,
  typeText,
  widthImg,
  heightImg,
  parentClass,
  image,
}) => (
  <div className={`${parentClass}__wrapRow${number}`}>
    <div className={`${parentClass}__row${number}`}>
      <div className={`${parentClass}__row${number}-wrapText`}>
        <div className={`${parentClass}__row${number}-title`}>
          {content.title}
        </div>
        {typeText === "str" ? (
          <div className={`${parentClass}__row${number}-text`}>
            {content.text}
          </div>
        ) : (
          <ToolsRowList text={content.text} />
        )}
      </div>
      <div className={`${parentClass}__row${number}-wrapImage`}>
        {image ?? (
          <ToolsRowImage
            data={content.img}
            number={number}
            widthImg={widthImg}
            heightImg={heightImg}
            classes={`${parentClass}__row`}
          />
        )}
      </div>
    </div>
  </div>
);
export default ToolsRow;
