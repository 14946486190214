import React from "react";

const Paragraph = ({ text }) => (
  <div className="PgCMain-S__paragraph">
    {text.map((p, i) => (
      <div key={i}>{p}</div>
    ))}
  </div>
);

export default Paragraph;
