import React, { useContext } from "react";
import { LayoutContext } from "../../../store/context/layout-context";
import { PAGE_OPTIONS, PAGES } from "../../../helpers/constants";
import SectionEmailCollect from "./SectionEmailCollect";

const SectionSix = () => {
  const { isCnUser } = useContext(LayoutContext);
  const redirectUrl =
    PAGE_OPTIONS[PAGES.CN].INTRO_REDIRECTED_URL[isCnUser ? "CN" : "EN"];

  return (
    <SectionEmailCollect
      redirectUrl={redirectUrl}
      redirect
      buttonTextSecondState="免费试用AMZ侦探!"
    />
  );
};
export default SectionSix;
