import React from "react";
import PropTypes from "prop-types";
import "../ControlsEmailCollect/index.scss";

const GetForm = (props) => {
  const {
    placeholderInput,
    emailValue,
    // isLoading,
    buttonTextFirstState,
    information,
    btnMaxContent,
    onEmailChange,
    onSubmit,
    onError,
    onKey,
  } = props;
  return (
    <div className="emailCollect-form">
      <div className="emailCollect-form__sendEmail">
        <input
          type="email"
          placeholder={
            !onError ? placeholderInput || "example@gmail.com" : onError
          }
          className={`emailCollect__input ${onError ? "error" : ""}`}
          value={emailValue}
          onChange={onEmailChange}
          onKeyDown={onKey}
        />
        <button
          type="button"
          onClick={onSubmit}
          // disabled={isLoading}
          className={`bt emailCollect__bt emailCollect__bt_orange ${
            btnMaxContent ? "bt-maxContent" : ""
          }`}
        >
          {buttonTextFirstState}
        </button>
      </div>
      <p className="emailCollect__info">{information}</p>
    </div>
  );
};
GetForm.propTypes = {
  placeholderInput: PropTypes.string,
  emailValue: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
  information: PropTypes.string,
};

export default GetForm;
