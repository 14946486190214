import React, { useContext } from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import SectionEmailCollect from "../SectionEmailCollect";
import "./index.scss";
import { LayoutContext } from "../../../../store/context/layout-context";

const SectionFive = ({ handleClick }) => {
  const { isCnUser } = useContext(LayoutContext);
  return (
    <section
      className={getTrueClasses(
        "PgCMain-SectionFive"
        // isOpacity && "PgCMain-SectionFive__opacity"
      )}
    >
      <SectionEmailCollect
        isCnUser={isCnUser}
        redirect={!isCnUser}
        handleClick={handleClick}
        buttonTextSecondState="安装 AMZ侦探 PRO Extension"
        installViewCustomClass="PgCMain-SectionFive__redirectedButton"
        showMobileProPopup={!isCnUser}
      />
    </section>
  );
};

SectionFive.propTypes = {
  handleClick: PropTypes.func,
  setIsSecondStateCollectEmail: PropTypes.func,
  isSecondStateCollectEmail: PropTypes.bool,
  isCnUser: PropTypes.bool,
  isOpacity: PropTypes.bool,
};
export default SectionFive;
