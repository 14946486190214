import React, { useContext, useEffect, useState } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import { LayoutContext } from "../../../../store/context/layout-context";
import PropTypes from "prop-types";
import { PAGE_OPTIONS, PAGES } from "../../../../helpers/constants";
import useCheckLicense from "../../../../hooks/useCheckLicense";
import { graphql, useStaticQuery } from "gatsby";
import { getTrueClasses } from "../../../../helpers";
import IntroPoints from "./IntroPoints";
import LicenseEmailCollectContainer from "../../../../containers/common/forms/LicenseEmailCollectContainer";
import IntroOffer from "./IntroOffer";
import IntroScreenshot from "./IntroScreenshot";
import "./emailCollect.scss";
import "./index.scss";

const Intro = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const { isCnUser } = useContext(LayoutContext);
  const [getDisplayError, setDisplayError] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const isPaid = useCheckLicense();

  const redirectUrl = PAGE_OPTIONS[PAGES.CN].INTRO_REDIRECTED_URL[isCnUser ? "CN" : "EN"]

  useEffect(() => {
    const timer = isShowVideo
      ? setTimeout(() => {
          setDisplayError(true);
        }, 4000)
      : null;
    return () => clearTimeout(timer);
  }, [isShowVideo]);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/cn-home/" }, ext: { eq: ".jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);
  return (
    <section className="PgCMain-I" id="home">
      <div className="PgCMain-I__container">
        <div
          className={getTrueClasses(
            "PgCMain-I__info",
            isAuth && "PgCMain-I__info_secondState"
          )}
        >
          <h1 className="PgCMain-I__title">用于在亚马逊上运营的一整个工具箱</h1>
          <IntroPoints />
          <LicenseEmailCollectContainer
            page={PAGES.CN}
            buttonTextFirstState="免费试用AMZ侦探!"
            buttonTextSecondState={
              isPaid ? "免费试用AMZ侦探!" : "免费试用AMZ侦探!"
            }
            customClass="PgCMain-I-emailCollect"
            redirectUrl={redirectUrl}
            language="cn"
          />
          <IntroOffer isPaid={isPaid} />
        </div>
        <IntroScreenshot
          isShowVideo={isShowVideo}
          setIsShowVideo={setIsShowVideo}
          data={data}
          getDisplayError={getDisplayError}
        />
      </div>
    </section>
  );
};

Intro.propTypes = {
  redirectUrl: PropTypes.string,
};

export default Intro;
