const PG_C_MAIN_SECTION_RATING_REVIEW_CONTENT = [
  {
    title: "这是在亚马逊上工作的一个极好的工具...",
    description: () => "这是在亚马逊上工作的一个极好的工具。我已经使用AMZ侦探三个月了。非常便利。所有数据都在一个地方。强烈推荐。"
  },
  {
    title: "产品研究的绝佳工具...",
    description: () => "产品研究的绝佳工具。包装有实用的功能，确保您找到理想的产品。"
  },
  {
    title: "AMZ侦探服务非常好...",
    description: () => "AMZ侦探服务非常好。我喜欢这个工具和功能。总的来说，是一个不错的产品，如果你正处于选品阶段，强烈建议大家尝试一下。"
  },
]

export default PG_C_MAIN_SECTION_RATING_REVIEW_CONTENT
