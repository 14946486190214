import React from "react";
import SVG_Dot from "../../../../../assets/images/vector/components/common/dots.svg";
import "./index.scss";

const POINTS_TEXT = [
  "找到最高利润和低竞争产品",
  "来自本地和国际供应商数据库",
  "获取最流行和还没发现的产品关键词",
];

const IntroPoints = () => (
  <ul className="PgCMain-I-Points">
    {POINTS_TEXT.map((text, i) => (
      <li key={i}>
        <img src={SVG_Dot} alt="-" />
        {text}
      </li>
    ))}
  </ul>
);

export default IntroPoints;
