import React, { useContext } from "react";
import { getTrueClasses } from "../../../../helpers";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import { PAGES } from "../../../../helpers/constants";
import "./index.scss";
import CnEmailCollectContainer from "../../../../containers/common/forms/CnEmailCollectContainer";

const SectionEmailCollect = (props) => {
  const {
    redirectUrl,
    onSuccess,
    redirect,
    buttonTextSecondState,
    installViewCustomClass,
    isCnUser,
    handleClick,
    showMobileProPopup
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <section className="PgCMain-SectionEmailCollect">
      <div
        className={getTrueClasses(
          "PgCMain-SectionEmailCollect__wrap",
          isAuth && "PgCMain-SectionEmailCollect__secondState"
        )}
      >
        <CnEmailCollectContainer
          page={PAGES.CN}
          buttonTextSecondState={buttonTextSecondState}
          noCreditCardRequired="无需信用卡"
          buttonTextFirstState="免费试用AMZ侦探!"
          customClass="cn-sectionEmailCollect"
          isNodeTitle
          title="输入您的电子邮件地址以开始免费试用！"
          installViewCustomClass={installViewCustomClass}
          information="通过输入您的电子邮件地址，您同意接收来自AMZ侦探的营销电子邮件。"
          redirectUrl={redirectUrl}
          redirect={redirect}
          onSuccess={onSuccess}
          isCnUser={isCnUser}
          handleClick={handleClick}
          showMobileProPopup={showMobileProPopup}
        />
      </div>
    </section>
  );
};

export default SectionEmailCollect;
